import React from 'react'
import { Box } from '@rebass/grid'
import BlogHeader from './BlogHeader'
import Row from '../../page-content-row'
import BlogContentRows from './BlogContentRows'
import BlogRelated from './BlogRelated'
import styled from 'styled-components'
import SEO from '../../seo'

const PageBlog = ({ data }) => {
  const postData = data?.allContentfulPost
  const { seo } = postData || {}
  const postTitle = postData?.title.replace(/<\/?[^>]+(>|$)/g, '')

  return (
    <Row>
      <Wrapper>
        <SEO
          title={seo?.title || postTitle}
          description={seo?.description}
          image={'https:' + seo?.image?.fixed?.src}
        />
        <BlogHeader data={postData} />
        <BlogContentRows data={postData} />
        <BlogRelated data={postData} />
      </Wrapper>
    </Row>
  )
}
export default PageBlog

const Wrapper = styled(Box)``

import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import SvgBlogCarouselPrev from '../../../svg/SvgBlogCarouselPrev'
import SvgBlogCarouselNext from '../../../svg/SvgBlogCarouselNext'

const PaginationWrapperButton = styled(Box)`
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ direction }) => (direction === 'prev' ? 'left: 28px;' : 'right: 28px;')}
  svg {
    display: block;
  }

  @media (max-width: 800px) {
    display: none;
  }
  user-select: none;

  opacity: 0.25;

  transition: all 250ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

export const PaginationPrev = ({ swiper }) => {
  return (
    <PaginationWrapperButton
      direction="prev"
      onClick={() => swiper.slidePrev()}
    >
      <SvgBlogCarouselPrev />
    </PaginationWrapperButton>
  )
}

export const PaginationNext = ({ swiper }) => {
  return (
    <PaginationWrapperButton
      direction="next"
      onClick={() => swiper.slideNext()}
    >
      <SvgBlogCarouselNext />
    </PaginationWrapperButton>
  )
}

export const Pagination = ({ images, currentIndex, swiper }) => {
  return (
    <PaginationWrapper>
      {images?.map((_, index) => (
        <PaginationDot
          key={index}
          isCurrent={currentIndex === index}
          onClick={() => swiper.slideTo(index)}
        ></PaginationDot>
      ))}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  height: 9px;
  align-items: center;
  margin-top: 16px;
  justify-content: flex-end;

  @media (max-width: 800px) {
    margin-right: 16px;
  }
`

const PaginationDot = styled(Box)`
  width: ${({ isCurrent }) => (isCurrent ? '9px' : '3px')};
  height: ${({ isCurrent }) => (isCurrent ? '9px' : '3px')};
  border-radius: 100%;
  background-color: ${({ isCurrent }) => (isCurrent ? '#4C4E55' : '#ABBDC8')};
  transition: all 0.3s ease-in-out;

  position: relative;

  cursor: pointer;

  &:hover {
    width: 9px;
    height: 9px;
  }
`

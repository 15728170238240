import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../../util/style'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const BlogQuoteRow = ({ data }) => {
  const { quote, content, headerSize } = data || {}

  return (
    <Wrapper headerSize={headerSize}>
      <InnerWrapper>
        {content || null}
        {quote && (renderRichText(quote) || null)}
      </InnerWrapper>
    </Wrapper>
  )
}

export default BlogQuoteRow

const InnerWrapper = styled(Box)`
  width: 80%;
  i {
    font-style: normal;
    font-family: ${theme.font.fontFamilySerifItalic};
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  b {
    font-weight: normal;
    font-style: normal;
    font-family: ${theme.font.fontFamilyMedium};
  }

  a {
    color: ${theme.colors.grey1};
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }

  h5 {
    font-family: ${theme.font.fontFamilyRegular};
    font-weight: normal;
    margin-top: 55px;
    font-size: 16px;
    margin-bottom: 0;
    @media (max-width: 800px) {
      margin-top: 22px;
      font-size: 14px;
    }
    letter-spacing: 0.8px;
  }

  @media (max-width: 800px) {
    width: 95%;
  }
`

const Wrapper = styled(Box)`
  font-size: ${({ headerSize }) => (headerSize === 'Large' ? '40px' : '30px')};
  font-family: ${theme.font.fontFamilySerifThin};
  text-align: center;

  line-height: 130%;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    font-size: ${({ headerSize }) =>
      headerSize === 'Large' ? '30px' : '25px'};
  }
`

import React from 'react'

import BlogMediaRowFull from './BlogMediaRowFull'
import BlogOverviewRow from './BlogOverviewRow'
import BlogMediaRowCols from './BlogMediaRowCols'
import BlogCopyRow from './BlogCopyRow'
import BlogSubscribeRow from './BlogSubscribeRow'
import BlogContainer from './BlogContainer'
import BlogTitleRow from './BlogTitleRow'
import BlogQuoteRow from './BlogQuoteRow'

const BlogContentRows = ({ data }) => {
  const { rows } = data

  return rows?.map((row) => {
    const { __typename: rowType } = row
    const { attachTop = false, attachBottom = false } = row

    switch (rowType) {
      case 'ContentfulOverviewRow':
        return (
          <BlogContainer attachTop={attachTop} attachBottom={attachBottom}>
            <BlogOverviewRow data={row} />
          </BlogContainer>
        )
      case 'ContentfulMediaRowFull':
        return (
          <BlogContainer attachTop={attachTop} attachBottom={attachBottom}>
            <BlogMediaRowFull data={row} />
          </BlogContainer>
        )
      case 'ContentfulMediaRowCols':
        return (
          <BlogContainer attachTop={attachTop} attachBottom={attachBottom}>
            <BlogMediaRowCols data={row} />
          </BlogContainer>
        )
      case 'ContentfulCopyRow':
        const isNoMedia = !row?.image && !row?.video

        return (
          <BlogContainer
            pt={isNoMedia ? ['26px', '96px'] : null}
            pb={isNoMedia ? ['53px', '93px'] : null}
            attachTop={attachTop}
            attachBottom={attachBottom}
          >
            <BlogCopyRow data={row} />
          </BlogContainer>
        )
      case 'ContentfulSubscribeRow':
        return (
          <BlogContainer attachTop={attachTop} attachBottom={attachBottom}>
            <BlogSubscribeRow data={row} />
          </BlogContainer>
        )
      case 'ContentfulTitleRow':
        return (
          <BlogContainer
            pt={['118px', '147px']}
            pb={['113px', '137px']}
            borderTop={Boolean(row?.topSeparator)}
          >
            <BlogTitleRow data={row} />
          </BlogContainer>
        )
      case 'ContentfulQuoteRow':
        return (
          <BlogContainer
            pt={['52px', '91px']}
            pb={['48px', '86px']}
            attachTop={attachTop}
            attachBottom={attachBottom}
            borderTop={Boolean(row?.topSeparator)}
          >
            <BlogQuoteRow data={row} />
          </BlogContainer>
        )
      default:
        return null
    }
  })
}

export default BlogContentRows

import React from 'react'
import PageBlog from '../components/blog-page/PageBlog'
import { graphql } from 'gatsby'

const Post = ({ data }) => {
  return <PageBlog data={data} />
}
export default Post

export const query = graphql`
  query getPost($slug: String!) {
    allContentfulPost: contentfulPost(slug: { eq: $slug }) {
      entryTitle
      title
      slug
      category
      headerTheme
      thumbnail {
        ...media
      }
      headerImage {
        ...media
      }
      headerInnerImage {
        ...media
      }
      headerSize
      headerBgColour
      headerFgColour
      headerFgAltColour
      rows {
        ... on ContentfulCopyRow {
          ...copyRow
        }
        ... on ContentfulMediaRowCols {
          ...mediaRowCols
        }
        ... on ContentfulMediaRowFull {
          ...mediaRowFull
        }
        ... on ContentfulOverviewRow {
          ...overviewRow
        }
        ... on ContentfulSubscribeRow {
          ...subscribeRow
        }
        ... on ContentfulTitleRow {
          ...titleRow
        }
        ... on ContentfulQuoteRow {
          ...quoteRow
        }
      }
      relatedPosts {
        id
        slug
        title
        category
        thumbnail {
          ...media
        }
      }
      seo {
        title
        description
        image {
          ...media
        }
      }
    }
  }

  fragment subscribeRow on ContentfulSubscribeRow {
    id
    entryTitle
    copy {
      raw
    }
    image {
      ...media
    }
    theme
    buttonText
    formTitle
    hubspotConversionType
    fgColour
    bgColour
    attachTop
    attachBottom
  }

  fragment overviewRow on ContentfulOverviewRow {
    id
    entryTitle
    heading
    body {
      raw
    }
    attachTop
    attachBottom
  }

  fragment mediaRowFull on ContentfulMediaRowFull {
    id
    entryTitle
    style
    media {
      ...media
    }
    images {
      ...media
    }
    videoCover {
      ...media
    }
    video {
      id
      uploadId
      assetId
      playbackId
      ratio
      duration
    }
    mediaCaption {
      mediaCaption
    }

    attachTop
    attachBottom
  }

  fragment mediaRowCols on ContentfulMediaRowCols {
    id
    entryTitle
    style
    media1 {
      ...media
    }
    media1Caption {
      media1Caption
    }
    media2 {
      ...media
    }
    media2Caption {
      media2Caption
    }
    attachTop
    attachBottom
  }

  fragment copyRow on ContentfulCopyRow {
    id
    entryTitle
    image {
      ...media
    }
    images {
      ...media
    }
    videoCover {
      ...media
    }
    video {
      id
      uploadId
      assetId
      playbackId
      ratio
      duration
    }
    copy {
      raw
    }
    attachTop
    attachBottom
    mediaCaption {
      mediaCaption
    }
  }

  fragment titleRow on ContentfulTitleRow {
    id
    entryTitle
    title
    topSeparator
    attachTop
    attachBottom
  }

  fragment quoteRow on ContentfulQuoteRow {
    id
    entryTitle
    quote {
      raw
    }
    topSeparator
    headerSize
    attachTop
    attachBottom
  }

  fragment media on ContentfulAsset {
    fluid {
      src
    }
    fixed {
      width
      height
      src
    }
    __typename
    description
    id
    file {
      contentType
      url
    }
    gatsbyImageData(layout: FULL_WIDTH)
  }
`
